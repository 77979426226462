/**
 * RichText internal link are rendered without language parameter.
 * Internal links are 'attrs' with  'linktype': 'story' and 'href', 'href' value is rendered link.
 */
import { cloneDeep } from 'lodash';

export function localizedRenderRichText(richTextBlok, localizationFunction) {
  const entireObj = cloneDeep(richTextBlok);
  const keyToFind = 'linktype';
  const valToFind = 'story';

  /**
   * Reference for usage of naturally recursive nature JSON.stringify  https://stackoverflow.com/questions/15523514/find-by-key-deep-in-a-nested-array#answer-56204398
   */
  JSON.stringify(entireObj, (_, nestedValue) => {
    if (nestedValue?.href && nestedValue[keyToFind] === valToFind) {
      nestedValue.href = localizationFunction(nestedValue.href);
    }

    return nestedValue;
  });

  return renderRichText(entireObj);
}
